
import React from "react";
import "./Services.scss";
import Page from "app/utils/Page/Page";
import Header from "app/components/molecules/Header/Header";
import Quotes from "app/components/atoms/Quotes/Quotes";
import RenderHTML from "app/components/atoms/RenderHtml";


const SkillList = (props) => {
  const { data, img, title } = props;
  const list =
    data.map((item, i) => {
      if (item) {
        return (
          <li key={i}>{item}</li>
        )
      }
    })
  return (
      <div className="skill-list">
        <ul>
          {list}
        </ul>
      </div>

  )
}


const Services = () => {

  const data = window.siteData.services;

  const aboutValues = window.siteData.aboutusValues;
  let skillsList = [];

  aboutValues.forEach(element => {
    skillsList.push(element['Skills list'])
  });

  return (
    <Page
      id="s-config-tool"// {`${TargetConfig.id}-config-tool`}
      title="Turris home health solutions"
      description="Turris home health solutions" // {TargetConfig.description}
    >
      <div className="section-container bg-cover">

        <Header page="services" />

        <section className="sub-section services">
        {
            data.map((item, i) => {

              if (item.type === 'H1') {
                return (
                  <span key={i}>
                  {
                    i != 0 &&
                    <img src="images/icons/red-heart-icon.svg" className="heart" />
                  }
                  <h2 style={{color:i==0?'#ED1E45': '#0a1633'}}>{item.value}</h2>
                  </span>
                )
              } else
                if (item.type === 'H2') {
                  return (
                    <span key={i}>
                    {
                      i != 0 &&
                      <img src="images/icons/red-heart-icon.svg" className="heart" />
                    }
                    <h3 >{item.value}</h3>
                    </span>
                  )
                } else {
                  return (
                    <p key={i} ><RenderHTML HTML={item.value} /></p>
  
                  )
                }

            })
          }

          <div className="skill-list-box">
            <SkillList data={skillsList} />
          </div>

        </section>

      </div>

      <Quotes page="services" />

    </Page>
  )
}

export default Services;
