
import React from "react";
import "./Disclaimers.scss";
import Page from "app/utils/Page/Page";
import { capitalizeFirstLetter } from "app/utils/helper";
import Header from "app/components/molecules/Header/Header";



const Disclaimers = () => {

  const data = window.siteData.aboutUs;

  const aboutValues = window.siteData.aboutusValues;
  let aboutUsValuesData = { commitment: [], courage: [], respect: [] };

  // find the order of  commitment, courage, respect
  let listOrder = [], index = 0;
  for (const key in aboutValues[0]) {
    if (index <= 2) {
      listOrder.push(key.toLowerCase())
    }
    index++;
  }

  aboutValues.forEach(element => {

    aboutUsValuesData.commitment.push(element.Commitment)
    aboutUsValuesData.courage.push(element.Courage)
    aboutUsValuesData.respect.push(element.Respect)
  });


  return (
    <Page
      id="disclaimers"// {`${TargetConfig.id}-config-tool`}
      title="Turris home health solutions"
      description="Turris home health solutions" // {TargetConfig.description}
    >
      <section>
        <div className="disclaimers">

          <div className="head">
            <h2>Disclaimers</h2>
          </div>


          <div className="content">
            <p>
              Our website is linked to other websites of which we have no control. We make no representations about the accuracy of information contained on those websites. TURRIS Home Health Solutions does not guarantee and accepts no legal liability whatsoever, arising from or connected to, the accuracy, reliability, currency or completeness of any material contained on this website or any linked site. We recommend that users exercise their own discretion and care with respect to their use of this website and carefully evaluate materials on other linked websites.
            </p>
            <p>
              TURRIS Home Health Solutions have put in place security measures to protect this website from unauthorized access. We however do not warrant that the material will not cause damage, or is free from computer virus, or errors or any other defects. The internet is an insecure public network, hence the potential risk that a user’s transactions are being viewed, intercepted or modified by third parties, or that files that the user downloads may contain computer viruses, disabling codes, worms or other defects. TURRIS Home Health Solutions accepts no liability for any interference with or damage to a user’s computer systems, software or data occurring in connection with or relating to this website or it’s use. Users are encouraged to take appropriate and adequate precautions to ensure that whatever is selected from this site does not contain viruses or other contaminations that may interfere with or damage the user’s computer system, software or data.
            </p>
            <p>
              TURRIS Home Health Solutions uses several social media and professional sites such as Facebook and LinkedIn, for networking purposes. TURRIS Home health Solutions is not responsible for and does not endorse user content transmitted or posted on its social media pages, nor does it guarantee the accuracy, integrity or quality of the user content. TURRIS Home Health Solutions accepts no liability whatsoever for any user content provided on its social media pages and disclaims all liability from any loss or damage which may arise from the use of User content posted on its social media pages. Social media services have their own terms of use and privacy policies on the collection, use and disclosure of user’s personal information, over which TURRIS Home Health Solutions have no control. Users should familiarise themselves with the terms of use and privacy policies of each social media or professional networking service before use. Users should also exercise their own discretion and care with respect to their use of these social media pages.

            </p>

          </div>
        </div>
      </section>

    </Page>
  )
}

export default Disclaimers;
