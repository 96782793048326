import React from "react";
import "./Quotes.scss";




const Quotes = (props) => {

  const {page} = props;

  let quotes = {text:  "We rise by lifting others", person:"Robert Ingersoll", page: page}


  if(window.siteData.quotes){

    quotes = window.siteData.quotes.filter(d => d.page === page)[0]

  }

  return (
    <section>
      <div className="quote-section">
        <p>
          “{quotes.text}”
        </p>
        <p>-{quotes.person}</p>
      </div>
    </section>

  )
}

export default Quotes;
