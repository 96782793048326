import React, { Component } from "react";
import { withRouter } from "react-router";
import Helmet from "react-helmet";
import defaultImg from "./default-image.jpg";
import Footer from "app/components/molecules/Footer";

const SITE_URL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://turrishomehealthsolutions.com.com";

const defaultTitle = "turrishomehealthsolutions.com.au blogs";
const defaultDescription = "";
const defaultImage = `${SITE_URL}${defaultImg}`;
const defaultSep = " | ";


class Page extends Component { 
  getMetaTags(
    {
      title,
      description,
      image,
      socialImage,
      contentType,
      noCrawl,
      published,
      updated,
      category,
      tags
    },
    pathname
  ) {
    const metaTitle = title
      ? (title + defaultSep + defaultTitle).substring(0, 60)
      : defaultTitle;
    const metaDescription = description
      ? description.substring(0, 155)
      : defaultDescription;
    const metaImage = image ? `${SITE_URL}pathname/${image}` : defaultImage;

    const metaTags = [
      { itemprop: "name", content: metaTitle },
      { itemprop: "description", content: metaDescription },
      { itemprop: "image", content: metaImage },
      { name: "description", content: metaDescription },
      { name: "twitter:card", content: "summary_large_image" },
      { name: "twitter:title", content: metaTitle },
      { name: "twitter:description", content: metaDescription },
      { name: "twitter:image:src", content: metaImage },
      { property: "og:title", content: metaTitle },
      { property: "og:type", content: contentType || "website" },
      { property: "og:url", content: SITE_URL+ pathname },
      { property: "og:image", content: metaImage },
      { property: "og:description", content: metaDescription },
      { property: "og:site_name", content: defaultTitle }
    ];

    if (noCrawl) {
      metaTags.push({ name: "robots", content: "noindex, nofollow" });
    }

    if (published) {
      metaTags.push({ name: "article:published_time", content: published });
    }
    if (updated) {
      metaTags.push({ name: "article:modified_time", content: updated });
    }
    if (category) {
      metaTags.push({ name: "article:section", content: category });
    }
    if (tags) {
      metaTags.push({ name: "article:tag", content: tags });
    }
 
    // console.log("metaTags metaTags metaTags metaTags")
    // console.log(metaTags)
    return metaTags;
  }


  render() {
    const { children, id, className, ...rest } = this.props; 

    return (
      <>
        
        {children}

        <Footer />
      </>
    );
  }
}

export default withRouter(Page);
