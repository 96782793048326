
import React from "react";
import "./AboutUs.scss";
import Page from "app/utils/Page/Page";
import { capitalizeFirstLetter } from "app/utils/helper";
import Header from "app/components/molecules/Header/Header";
import RenderHTML from "app/components/atoms/RenderHtml";

const Pinklist = (props) => {
  const { data, img, title } = props;
  const list =
    data.map((item, i) => {
      if (item) {
        return (
          <li key={i}>{item}</li>
        )
      }
    })
  return (
      <div className="pink-list">
        <div><span><img src={img} /></span>{title}</div>
        <ul>
          {list}
        </ul>
      </div>

  )
}


const AboutUs = () => {

  const data = window.siteData.aboutUs;

  const aboutValues = window.siteData.aboutusValues;
  let aboutUsValuesData = { commitment: [], courage: [], respect: [] };

  // find the order of  commitment, courage, respect
  let listOrder = [], index = 0;
  for (const key in aboutValues[0]) {
    if(index <= 2){
      listOrder.push(key.toLowerCase())
    }
    index++;
  }

  aboutValues.forEach(element => {
    aboutUsValuesData.commitment.push(element.Commitment)
    aboutUsValuesData.courage.push(element.Courage)
    aboutUsValuesData.respect.push(element.Respect)
  });


  return (
    <Page
      id="s-config-tool"// {`${TargetConfig.id}-config-tool`}
      title="Turris home health solutions"
      description="Turris home health solutions" // {TargetConfig.description}
    >
      <div className="section-container bg-cover">

        <Header page="aboutus" />

        <div className="sub-section aboutus">
        {
            data.map((item, i) => {

              if (item.type === 'H1') {
                return (
                  <span key={i}>
                  {
                    i != 0 &&
                    <img src="images/icons/red-heart-icon.svg" className="heart" />
                  }
                  <h2 style={{color:i==0?'#ED1E45': '#0a1633'}}>{item.value}</h2>
                  </span>
                )
              } else
                if (item.type === 'H2') {
                  return (
                    <span key={i}>
                    {
                      i != 0 &&
                      <img   src="images/icons/red-heart-icon.svg" className="heart" />
                    }
                    <h3 >{item.value}</h3>
                    </span>
                  )
                } else {
                  return (
                    <p key={i} ><RenderHTML HTML={item.value} /></p>
  
                  )
                }

            })
          }

          <div className="pink-list-box">

          {
            listOrder.map((item, i) => {

                return(
                  <Pinklist key={`er-${i}`}  data={aboutUsValuesData[item]} img={`./images/icons/icon-${item}-about-us.svg`} title={capitalizeFirstLetter(item)} />
                )
            })

          }
          {/* 
          
            <Pinklist data={aboutUsValuesData.commitment} img="./images/icons/icon-courage-about-us.svg" title="Commitment:" />
            <Pinklist data={aboutUsValuesData.courage} img="./images/icons/icon-courage-about-us.svg" title="Courage:" />
            <Pinklist data={aboutUsValuesData.respect} img="./images/icons/icon-respect-about-us.svg" title="Respect:" /> */}
          </div>

        </div>

      </div>

    </Page>
  )
}

export default AboutUs;
