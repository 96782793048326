
// @flow
import React from 'react';
import SanitizedHTML from "react-sanitized";


const RenderHTML = (props:Props) => {
  return (
    <SanitizedHTML
      options={{
        allowedTags:false, //["div","ul","em","li","a","p","img","span","strong","b","br","hr","sup"],
        allowedAttributes:false
      }}
      html={props.HTML}
    />
  );
};

export default RenderHTML;


