import React from "react";
import { Link } from "react-router-dom";
import { root } from "app/utils/helper";
import "./MakeAppointment.scss";


// See 'Avoiding Flash Of Loading Component' for reason as to this 'pastDelay' prop
// and its use here: https://github.com/jamiebuilds/react-loadable

const MakeAppointment = () => (
  <div className="phone">
  <div className="phone-sub">
    <img src="./images/health/phone-icon.png" />
    <div className="number">
      <p>Phone:<br className="desktop-br"/>+61 468 963 048</p>
    </div>
  </div>

  <div className="make-appt">
    <Link to={`${root}contactus`} alt="make an appointment" >
      <p>Make an<br className="desktop-br"/> appointment</p>
    </Link>
  </div>
</div>
)

export default MakeAppointment;
