
import React, { useEffect, useState } from "react";
import Page from "app/utils/Page/Page";
import { Link } from "react-router-dom";
import "./Home.scss";
import Header from "app/components/molecules/Header/Header";
import Quotes from "app/components/atoms/Quotes/Quotes";
import RenderHTML from "app/components/atoms/RenderHtml";

const Home = (props) => {

  const [screenSize, setScreenSize] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenSize(window.innerWidth)
    }
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  const data = window.siteData;


  return (
    <Page
      id="s-config-tool"// {`${TargetConfig.id}-config-tool`}
      title="Turris home health solutions"
      description="Turris home health solutions" // {TargetConfig.description}
    >
      <div className="section-container bg-cover">


        <Header page="home" />

          <section className="projects-section" >

            {data.home &&
              data.home.map((item, i) => {

                const blockClass = i % 2 ? 'project-block' : 'project-block pb-reversed';
                const descClass = i % 2 ? "desc" : "desc desc-rev";
                const imgClass = i % 2 ? "image gs_reveal gs_reveal_fromLeft" : "image gs_reveal gs_reveal_fromRight";
                const h1Class = i % 2 ? "gs_reveal gs_reveal_fromLeft" : "gs_reveal gs_reveal_fromRight";
                //const pClass = "pd-text";
                const pClass = i % 2 ? "pd-text gs_reveal gs_reveal_fromRight" : "pd-text gs_reveal gs_reveal_fromLeft";


                let img = "./images/health/" + item.img;

                return (
                  <div className='block' key={i} >

                    <div className={blockClass}>

                      <img className={imgClass} src={img} />

                      <div className={descClass}>
                      <img src="images/icons/red-heart-icon.svg" className="heart" />
                        <h2 className={h1Class}>{item.title}</h2>

                        {item.detail &&
                          <p >
                            <RenderHTML HTML={item.detail} />
                          </p>
                        }
                        {item.link && (item.title === 'Who we are') &&
                          <div className="btn-more-cont">
                            <Link to={item.link} className="btn-readmore" >{item.linkLabel}</Link>
                          </div>
                        }

                      </div>

                    </div>
                  </div>
                )
              })
            }
          </section>


        <Quotes page="home" />



      </div>

    </Page>
  )
}

export default Home;
