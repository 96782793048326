import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter} from 'react-router-dom';
import App from "./app/App";
import "./index.scss";


ReactDOM.render(
     <BrowserRouter>
        <App /> 
     </BrowserRouter>
   , document.querySelector('#root'));

