
import React, { Component } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import SvgIcons from "./utils/SvgIcons/SvgIcons";
import NavBar from "./components/organisms/NavBar/NavBar";
import Routes from "./routes/routes";
import "./App.scss";
import MakeAppointment from "./components/atoms/MakeAppointment/MakeAppointment";
import fallBackData from "./fallback-data.json";

const isDevelopement = process.env.NODE_ENV === 'development';

// Register gsap plugins
gsap.registerPlugin(ScrollTrigger);

if (window.innerWidth < 550) {
  ScrollTrigger.config({
    // default is "resize,visibilitychange,DOMContentLoaded,load" so we can remove "resize" from the list:
    autoRefreshEvents: "visibilitychange,DOMContentLoaded,load"
  });
}


const headerImg = {
  headerImg: {
    home: {img:'home-header.jpeg', label: ''},
    aboutus: {img:'aboutus-header.jpeg', label: 'Our vision is simple...to improve lives'},
    services: {img:'services-header.jpeg', label: 'Our expertise'},
    contactus: {img:'contactus-header.jpeg', label: 'Get in touch'},
    partnerships:{img:'header-partnerships.jpeg', label: 'Doing much together'}
  }
}


class App extends Component {

  constructor() {
    super();
    this.state = {
      showFooter: false,
      screenWidth: window.innerWidth,
      data: undefined
    }
  }

  async componentDidMount() {

    if (!isDevelopement) {
      try {
        // this.setState({ hubStatus });
      } catch (error) {
        console.error(`Error:`, error);
      }
    }

    this.fetchJSONData()

    window.addEventListener("resize", this.handleResize);

  }


  async fetchJSONData() {
    const jsonUrl = process.env.NODE_ENV === 'development' ? './dummy-data/data.json' : './data/data.json';

    try {
      const response = await fetch(jsonUrl);
      if (!response.ok) {
        // throw new Error(`HTTP error! Status: ${response.status}`);
        // use fallback data
        console.log('data loading error occured')
        window.siteData = { ...fallBackData, ...headerImg };
        this.setState({ data: window.siteData })
      }
      const jsonData = await response.json();
      window.siteData = { ...jsonData, ...headerImg };
      this.setState({ data: window.siteData })

    } catch (error) {

      console.error('Error:', error);
    }
  }


  handleResize = () => {
    this.setState({ screenWidth: window.innerWidth })
  }


  render() {
    const { authenticated, location, user, showFooter } = this.props;

    const { data } = this.state;
    if (!data) return null;

    return (
      <div id="app">
        <SvgIcons carat cross social doublearrow loading />
        <NavBar
          isBeta={true}
        />
        {this.state.screenWidth < 768 &&
          <div className="mobile-make-appt">
            <MakeAppointment />
          </div>
        }
        <Routes />

      </div>
    );
  }
}


export default App;
