
import React, { Component } from 'react';
import { APP_API, CONTACT_FORM_ID } from "../../../../config";
//import debounce from "just-debounce-it";
import { validateEmail, validateMessage } from "../../../utils/helper";
// import ToxicityDetector from './ToxicityDetector';
// import SpamDetector from 'app/utils/SpamDetector/SpamDetector';
import axios from "axios";
import "./ContactForm.scss";
//import { data } from '@tensorflow/tfjs';

const errorMsg = ' All fields are required.';
const defaultError = `${errorMsg}`;
let errors = '';

const server = 'https://turrishomehealthsolutions.com.au/inquery.php'; // process.env.NODE_ENV === 'development' ? 'http://127.0.0.1:5003/goondrook' : 'https://rlama.cyclic.cloud'

//
// Component
// -----------------------------------------------------------

class ContactForm extends Component {

  constructor() {
    super();
    this.state = {
      formStatus: 'submit',
      formValid: false,
      data: { email: '', name: '', subject: '', message: '' }
    }
  }




  onSpamOrToxicDetection = (item) => {
    const errorDiv = document.getElementById("errorDiv")
    const isToxicMsg = "Invalid Message. Inappropriate words or sentences detected in the message.";
    const isSpamMsg = "Invalid Message. Spams detected in the message.";

    const inValidMsgError = item === 'spam' ? isSpamMsg : isToxicMsg;
    errorDiv.innerHTML = inValidMsgError;
    errorDiv.classList.add('blink_me');
    this.setState({ formValid: false, formStatus: 'submit' });
  }

  /**  Usigin OpenAi to check spam or toxicity  */
  isMesageSpamOrToxic = async (subject, message) => {

    if (subject === "" && message === "") {
      console.log("subject or message required")
      return;
    }

    let data = {
      subject: subject ? subject : "",
      message: message ? message : ""
    }

    const response = await fetch(server + "/spamdetection", {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorMessage = await response.text();
      console.log(errorMessage)
    }
    const res = await response.json();

    return res.data.isspam === "True" ? "spam" : res.data.istoxic === "True" ? "toxic" : ""
  }



  sendForm = async () => {
    const { data, formValid } = this.state;

    if(errors){
      const errorDiv = document.getElementById("errorDiv")
       errorDiv.innerHTML = errors;
      errorDiv.classList.add('blink_me');
      this.setState({ formValid: false })
    }

    if (!errors) {

      this.setState({ formStatus: 'submitting' });
      const errorDiv = document.getElementById("errorDiv")

      try {
        // const isSpamToxic = await this.isMesageSpamOrToxic("", data.message);
        // if (isSpamToxic) {
        //   this.onSpamOrToxicDetection(isSpamToxic);
        //   return;
        // }

        const customerSubject = data.subject
        const customerMessage =  data.message

        const payload = {
          data: {
            type: "turris",
            name: data.name,
            from: data.email,
            subject: customerSubject,
            message: customerMessage
          }
        }

        const response = await fetch(server, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload),
        });

        if (!response.ok) {
          const errorMessage = await response.text();
          console.log(errorMessage)
        }

        const res = await response.json();
        console.log(res)

        this.setState({ formStatus: 'submitted', formValid: false, data: { email: '', name: '', subject: '', message: '' } });

      } catch (error) {
        console.log(`Error:`, error);

        errorDiv.innerHTML = error;
        this.setState({ formValid: false, formStatus: 'submit' });
      }

    }

  }




  onInputChange = (e) => {

    const { data } = this.state;


    const errorDiv =  document.getElementById("errorDiv");
    errorDiv.innerHTML = "";


    this.setState({ data: { ...data, [e.target.id]: e.target.value }, formStatus: 'submit' }, () => {

      const newData = this.state.data;

      for (const [key, value] of Object.entries(newData)) {

        if (value === '') {
          errors = `Fields cannot be empty. ${errorMsg}`;
          return;
        }

        if (key === 'email' && !validateEmail(value)) {
          errors = `Invalid email. Check your email.`;
          return;
        }

        if (key === 'message' && !validateMessage(value)) {
          errors = `Invalid or incomplete message.`;
          return;
        }

      }

      errors = ''

    })

  }




  render() {
    const { formStatus, formValid, data } = this.state;
    return (
      <div id='contact-form'  >

        <h3>Fill up the form</h3>
        <p className="msg">Your email address will not be published. Required fields are marked *</p>
        <div id="errorDiv"> </div>
        <input type="text" id="email" className="newsletter-input email" placeholder="Your email *" onChange={this.onInputChange} value={data.email} />
        <input type="text" id="name" className="newsletter-input name" placeholder="Your name *" onChange={this.onInputChange} value={data.name} />
        <input type="text" id="subject" className="newsletter-input subject" placeholder="Subject *" onChange={this.onInputChange} value={data.subject} />
        <textarea id="message" className="newsletter-input message" placeholder="Your Message *" rows="4" cols="50" onChange={this.onInputChange} value={data.message} />


        <button className={formValid && (formStatus === 'submit' || formStatus === 'submitted') ? 'btn-active' : ''} onClick={this.sendForm}>

          {formStatus === 'submitting' &&
            <>
              <span>Processing ...</span>
              <div id="spinner" className="lds-ring"><div></div><div></div><div></div><div></div></div>
            </>
          }

          {(formStatus === 'submit' || formStatus === 'submitted') && <span>Get in touch</span>}

        </button>  
        
        {formStatus === 'submitted' && <span className="sent">Message sent</span>}

        

      </div>

    )
  }
}

export default ContactForm;





