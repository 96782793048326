
import React, { Component } from "react";
import { Link } from "react-router-dom";
import MakeAppointment from "app/components/atoms/MakeAppointment/MakeAppointment";
import { root } from "app/utils/helper";
import "./NavBar.scss";


let lastScroll = 0;

export default class NavBar extends Component {
  constructor() {
    super();
    this.state = {
      searchActive: false,
      lineInputCheck: false
    }
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = (e) => {
    const headerDiv = document.getElementById("n-header");
    if (window.scrollY > 150) {
      headerDiv.style.top = '-69px';
    } else {
      // headerDiv.style.top = '0';
    }
    if (window.scrollY < lastScroll) {
      headerDiv.style.top = '0';
    }
    lastScroll = window.scrollY;
  }



  onMobileMenuClick = (event) => {

    const lineInput = document.getElementById("line-input");

    const checkedVal = lineInput.checked;//('checked') ;


    const menuDiv = document.getElementById("menu");
    menuDiv.style.transform = checkedVal ? 'translate(-86%, 17px)' : 'translate(18%, 17px)';
    menuDiv.style.transformOrigin = '0% 0%';

    const firstSpanLine = document.getElementById("first-line");
    const secondSpanLine = document.getElementById("second-line");
    const thirdSpanLine = document.getElementById("third-line");

    firstSpanLine.style.transformOrigin = '2px 0px';
    firstSpanLine.style.transform = checkedVal ? 'rotate(45deg) translate(-2px, -1px)' : 'translate(0%, 0%)';


    secondSpanLine.style.opacity = checkedVal ? '0' : '1';
    secondSpanLine.style.transform = 'translate(0%, 0%)';

    thirdSpanLine.style.transformOrigin = '1px 2px';
    thirdSpanLine.style.transform = checkedVal ? 'rotate(-45deg) translate(-2px, -1px)' : 'translate(0%, 0%)';

  }


  closeMenu=()=>{
    const menuDiv = document.getElementById("menu");
    menuDiv.style.transform ='translate(18%, 9px)';
    menuDiv.style.transformOrigin = '0% 0%';

    const firstSpanLine = document.getElementById("first-line");
    const secondSpanLine = document.getElementById("second-line");
    const thirdSpanLine = document.getElementById("third-line");


    firstSpanLine.style.transformOrigin = '2px 0px';
    firstSpanLine.style.transform =  'translate(0%, 0%)';


    secondSpanLine.style.opacity = '1';
    secondSpanLine.style.transform = 'translate(0%, 0%)';

    thirdSpanLine.style.transformOrigin = '1px 2px';
    thirdSpanLine.style.transform =  'translate(0%, 0%)';

  }


  render() {
    //const { authenticated, isBeta } = this.props;
    const { searchActive } = this.state;

    return (
      <header id="n-header" className="nav-bar">

        <div className="nav-bar__content">

          <Link  to={`${root}`} alt="Home" >
            <img src={`./images/health/turris-logo.png`} alt="logo" />
          </Link>

          <div className="nav-menu-desktop">
            <Link to={`${root}`} alt="Home">Home</Link>
            <Link to={`${root}aboutus`} alt="About us">About us </Link>
            <Link to={`${root}services`} alt="Services">Services</Link>
            <Link to={`${root}partnerships`} alt="Partnerships">Partnerships</Link>
            <Link to={`${root}contactus`} alt="Contact us">Contact us</Link>
          </div>

          {window.innerWidth >= 768 &&
          <MakeAppointment />
          }
        

          <div className="nav-menu-mobile">
            <nav role="navigation">
              <div id="menuToggle">

                <input type="checkbox" id="line-input" onChange={this.onMobileMenuClick} />

                <span id="first-line"></span>
                <span id="second-line"></span>
                <span id="third-line"></span>

                <ul id="menu" style={{ width: window.innerWidth + 3 + 'px' }}>

                  <Link to={`${root}`}  alt="Home"  onClick={this.closeMenu}><li>Home</li></Link>
                  <Link to={`${root}aboutus`} alt="About us"  onClick={this.closeMenu}><li>About us</li></Link>
                  <Link to={`${root}services`} alt="Services"  onClick={this.closeMenu}><li>Services</li></Link>
                  <Link to={`${root}partnerships`} alt="Partnerships" onClick={this.closeMenu}><li>Partnerships</li></Link>
                  <Link to={`${root}contactus`} alt="Contact us"  onClick={this.closeMenu}><li>Contact us</li></Link>

                </ul>
              </div>
            </nav>
          </div>

        </div>

      </header>
    );
  }
}

// const mapDispatchToProps = dispatch =>
//   bindActionCreators({ logoutUser }, dispatch);


// // TODO: Type this properly. See the following thread: https://github.com/facebook/flow/issues/7125
// export default connect < any, any, any, _, _, _ > (
//   null,
//   mapDispatchToProps
// )(NavBar);
