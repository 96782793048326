import React from "react";
import { Link } from "react-router-dom";
import "./Header.scss";

// See 'Avoiding Flash Of Loading Component' for reason as to this 'pastDelay' prop
// and its use here: https://github.com/jamiebuilds/react-loadable

const Header = (props) => {

  const { page } = props;
  const data = window.siteData;

  let headerImg = data.headerImg[page].img;
  headerImg = window.innerWidth < 600 ? "./images/health/" + headerImg.split(".")[0] + "_mobile." + headerImg.split(".")[1] : "./images/health/" + headerImg;

  const headerLabel = data.headerImg[page].label;

  const siteMap = page === 'aboutus' ? 'Home / About Us' :
                  page === 'services' ? 'Home / Services' :
                  page === 'partnerships' ? 'Home / Partnerships' :
                  page === 'contactus' ? 'Home / Contact Us' : '';

  return (
    <>
      {page !== 'home' &&
        <section className="header-solid"  >
          <div className="content" >
            <div className="hero-content" style={{ backgroundColor: 'yellow' }} >

              <div className="left-s">

                {/* <div className="left-s-A"></div> */}

                <div className="details">

                  {/* <p className="site-map">{siteMap}</p> */}

                  <div className="sub-details">
                    <div className="cont">
                      <img src="images/icons/white-heart-icon.svg" />
                      <h2 className="header-h2">{headerLabel}</h2>
                    </div>
                   
                  </div>

                  <div></div>

                </div>

              </div>

              <div className="right-s" style={{ backgroundImage: `url('${headerImg}')` }}></div>

            </div>
          </div>
        </section>
      }



      {
        page === 'home' &&
        <div className="header">
        <section className="header-gradient"  >
          <div className="content" style={{ backgroundImage: `url('${headerImg}')` }}>
            <div className="pink-gradient"></div>
            <div className="hero-content" >
              <div className="left-s">

                <div className="left-s-A"></div>

                <div className="details">

                  <div className="sub-details">

                    <p className="welcome">Welcome to</p>
                    <h2><span className="h2-bold" >TURRIS</span> HOME</h2>
                    <h2>HEALTH SOLUTIONS</h2>
                    <p className="smarter">A Smarter Approach to Home Care</p>
                  </div>

                  <div></div>

                </div>

              </div>

              <div></div>

            </div>
          </div>
        </section>
        </div>
      }
    </>

  )
}

export default Header;
