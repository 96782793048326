
import React from "react";
// import missingThingsImage from "../../../assets/missing-things.svg";
import "./NotFoundPage.scss";

const NotFoundPage = () => (
  <main className="not-found-page">
    <div className="not-found-page__tag">404 error</div>
    <h1>We can't find the page you asked for</h1>
    <p>Either the URL was mistyped or the page may no longer be available.</p>
   
    {/* <img src={missingThingsImage} alt="No content found" /> */}
  </main>
);

export default NotFoundPage;
