
import React from "react";
import "./ContactUs.scss";
import Page from "app/utils/Page/Page";
import Header from "app/components/molecules/Header/Header";
import ContactForm from "../ContactForm/ContactForm";


const ContactUs = () => {

  const data = window.siteData.contact[0];

  return (
    <Page
      id="s-config-tool"// {`${TargetConfig.id}-config-tool`}
      title="Turris home health solutions"
      description="Turris home health solutions" // {TargetConfig.description}
    >
      <div className="section-container bg-cover">

        <Header page="contactus" />

        <section className="sub-section contact-us">

          <div className="left">
              <img src="images/icons/red-heart-icon.svg" className="heart" />
              <h2>How to reach us</h2>

              <p><span style={{fontWeight:'700'}}>Hours of Operation</span></p>
              <p>Monday-Thursday: 08:30am-4:30pm</p>

              <p>Friday: 08:30am-2:30pm</p> 
              <p>Saturday and Sunday: By Appointment only</p>

              <div className="c-group">
                  <img src="images/icons/Icon-phone-alt-contact-us.svg" />  
                  <p><span style={{fontWeight:'700'}}>Phone:</span> {data.phone}</p>
              </div>
            
              <div className="c-group">
                  <img src="images/icons/Icon-email-contact-us.svg" />  
                  <p><span style={{fontWeight:'700'}}>Our Email:</span> {data.email}</p>
              </div>

              <div className="c-group">
                  <img src="images/icons/Icon-location-contact-us.svg" />  
                  <p><span style={{fontWeight:'700'}}>Location:</span> {data.address}</p>
              </div>
    
              <p>Follow us on: 

                {/* <span><img className="cf-social"  src="images/icons/Icon-facebook.svg" />  </span>
                <span><img className="cf-social" src="images/icons/Icon-instagram.svg" />  </span> */}

                <a href={data['facebook link']} target="_blank"><img className="cf-social"  src="images/icons/Icon-facebook.svg" />  </a>
            <a href={data['linkedin link']} target="_blank"><img className="cf-social" src="images/icons/Icon-linkedin.svg" />  </a>
      
              {data['instagram link'] &&
                <a href={data['instagram link']} target="_blank"><img className="cf-social" src="images/icons/Icon-instagram.svg" />  </a>
              }
        
              </p>
              

          </div>

          <div className="right">
              <ContactForm />

              <div className="privacy">
              <p>Privacy policy</p>
              <p>We have a duty to protect your information which can be used to identify you. This includes information about your health, past and present. We may not use or disclose protected health information than is necessary to accomplish the purpose of the use or disclosure.</p>
              </div>
          </div>

        </section>

      </div>

    </Page>

  )};

  export default ContactUs;

 