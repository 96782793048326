import React from "react";
import "./ComingSoon.scss";
import image from "./loader.svg";

type Props = {
  error: boolean,
  pastDelay: boolean,
  retry: Function,
  timedOut: boolean
};
// See 'Avoiding Flash Of Loading Component' for reason as to this 'pastDelay' prop
// and its use here: https://github.com/jamiebuilds/react-loadable

const ComingSoon = () => (
  <div className="comingsoon" >
    <h2 className="intro">New site coming soon. Stay tuned for something new and fresh!.</h2>
    <img src="./images/road2.jpg" />
  </div>
)

export default ComingSoon;
