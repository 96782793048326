import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { root } from "app/utils/helper";
import './Footer.scss';



const Footer = (props) => {

  const data = window.siteData.contact[0];

  if(!data) return null;

  return (
    <div className="footer-container">
      <div className="footer">
        <div className="footer__header">

        <div className="acknowledgement-box">
              <h3>Acknowledgement of Country</h3>
              <p>We acknowledge the traditional owners and custodians of country throughout Australia and acknowledges their continuing connection to land, water, and community. We pay our respects to the people, the cultures, and the elders, past, present, and emerging.</p>
          </div>


          <div className="footer__header__logo-container">
            <div className={`footer__header__logo-container__logo `}>
              <div
                className="footer__header__logo-container__logo__image"
                style={{
                  backgroundImage: `url(./images/health/turris-logo.png)`
                }}
              />
              {/* eslint-disable-next-line */}
              <a
                className="footer__header__logo-container__logo__link"
                href={`https://turrishomehealthsolutions.com.au/`}
              />
            </div>
          </div>

              
            <a href={data['facebook link']} target="_blank"><img className="cf-social"  src="images/icons/Icon-facebook.svg" />  </a>
            <a href={data['linkedin link']} target="_blank"><img className="cf-social" src="images/icons/Icon-linkedin.svg" />  </a>
            {data['instagram link'] &&
              <a href={data['instagram link']} target="_blank"><img className="cf-social" src="images/icons/Icon-instagram.svg" />  </a>
            }
      
        </div>

        <div className="footer__copyright">
          <p className="footer__copyright__text">© {new Date().getFullYear()} TURRIS home health solutions.  <span style={{margin:'0 10px'}}>ABN: 87748970728</span>  All Rights Reserved | <Link to={`${root}contactus`} lt="privacy policy"  >Privacy Policy</Link> | <Link to={`${root}disclaimers`} lt="Disclaimers"  >Disclaimers</Link> | <Link to={`${root}contactus`} lt="contact us"  >Contact us</Link> </p> 
        </div>
      </div>
    </div>
  )

}

export default Footer;
